import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Deadlifts 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`5-Deadlifts (275/185)`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`15-T2B`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our monthly free class day so bring a friend!
 8:00 & 9:00am at The Ville and 10:00 & 11:00am at Louisville East.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Kids this Saturday from 9-9:30 at Louisville East.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 5, our annual 4 person team competition, will take place
this year on November 4th!  Get your teams together now.  More info to
come.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts August 26th.  Email Eric for more
details: Fallscitystrength\\@gmail.com`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      